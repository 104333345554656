import { Box, Button, Checkbox, Drawer, FormControlLabel, Grow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import { DataGrid } from '@mui/x-data-grid'
import CommonGridHeader, { CommonGridToolBarWithFilterText } from '../../../../Components/Common Components/CommonComponents'
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { Colors } from '../../../../Styles/Colors'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTime } from 'luxon'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation'
import { CurrencySymbol } from '../../../../Utility/Constants'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import dayjs from 'dayjs';
export default class BillWiseReports extends Component {
  constructor(props) {
    super(props)
    // const currentYear = new Date().getFullYear()
    const minDate = new Date('2024-04-01')
    const maxDate = new Date('2024-06-30')
    this.state = {
      billwiseReportData: [],
      page: 0,
      pageSize: 10,
      loading: false,
      advance: 0,
      cash: 0,
      card: 0,
      upi: 0,
      bank: 0,
      cheque: 0,
      concession: 0,
      minDate: minDate,
      maxDate: maxDate,
      totalbillAmount: 0,
      totalReceiveAmount: 0,
      credit: 0,
      showBox: false,
      FilterOpen: false,
      // Filter State
      DatePickerOpen: false,
      datePicker: new Date('2024-04-01'),
      patientName: '',
      mobileNumber: '',
      accountNumber: '',
      depInpatient: true,
      depOutpatient: true,
      depLaboratory: true,
      depOt: true,
      depRadiology: true,
      modRadiology: true,
      modInpatient: true,
      modOutpatient: true,
      modLaboratory: true,
      modOt: true,
      modRadiology: true,
      cashSelected: true,
      cardSelected: true,
      upiSelected: true,
      bankSelected: true,
      chequeSelected: true,
      totalAdvanceAmt: 0
    }
  }

  componentDidMount() {
    this.getBillwiseReportData()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }

  getBillwiseReportData = () => {
    try {
      let moduleType = []; let states = this.state;
      let departmentList = []; let paymentModeList = [];
      if (states.modOutpatient && states.modInpatient && states.modLaboratory && states.modRadiology && states.modOt) {
        moduleType?.push('all')
      } else {
        if (states.modOutpatient) {
          moduleType?.push('op')
        }
        if (states.modInpatient) {
          moduleType?.push('ip')
        }
        if (states.modLaboratory) {
          moduleType?.push('lab')
        }
        // if (states.modRadiology) {
        //   moduleType?.push('radiology')
        // }
        // if (states.modOt) {
        //   moduleType?.push('ot')
        // }
      }

      if (states.depOutpatient && states.depInpatient && states.depLaboratory && states.depRadiology && states.depOt) {
        departmentList?.push('all')
      } else {
        if (states.depOutpatient) {
          departmentList?.push('op')
        }
        if (states.depInpatient) {
          departmentList?.push('ip')
        }
        if (states.depLaboratory) {
          departmentList?.push('lab')
        }
        // if (states.depRadiology) {
        //   departmentList?.push('radiology')
        // }
        // if (states.depOt) {
        //   departmentList?.push('ot')
        // }
      }

      if (states.cashSelected) {
        paymentModeList?.push(1)
      }
      if (states.cardSelected) {
        paymentModeList?.push(2)
      }
      if (states.upiSelected) {
        paymentModeList?.push(3)
      }
      if (states.bankSelected) {
        paymentModeList?.push(5)
      }
      if (states.chequeSelected) {
        paymentModeList?.push(4)
      }
      let startDate = new Date(states.datePicker)
      let date = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
      this.setState({ loading: true })
      RestAPIService.getAccReport(Serviceurls.ACC_BILL_WISE_RPT_GET +
        '?patient_name=' + this.state.patientName +
        "&uhid=" + this.state.accountNumber +
        '&mobile_no=' + this.state.mobileNumber +
        '&payment_mode=' + paymentModeList +
        '&department=' + departmentList +
        '&module_type=' + moduleType +
        '&from_date=' + date
      ).then((response) => {
        if (response.data.status === 'success') {
          this.setState({ loading: false })
          this.setState({
            billwiseReportData: response.data.data,
            totalReceiveAmount: response.data.total_received_amount,
            totalbillAmount: response.data.total_bill_amount,
            credit: response.data.total_credit_amount,
            concession: response.data.total_discount_amount,
            cash: response.data.total_cash_amount,
            card: response.data.total_card_amount,
            upi: response.data.total_upi_amount,
            bank: response.data.total_bank_amount,
            cheque: response.data.total_cheque_amount,
            FilterOpen: false,
            loading: false,
            totalAdvanceAmt: response.data.total_advance_received_amount
          })
        }
      }).catch((error) => {
        this.setState({ loading: false })
        if (error?.response?.data?.message) {
          this.errorMessage(error.response.data.message)
        } else {
          this.errorMessage(error.message)
        }
      })
    } catch (error) {
      this.setState({ loading: false })
      this.errorMessage(error.message)
    }
  }


  getBillwiseReportDataPrint = () => {
    try {
      let moduleType = []; let states = this.state;
      let departmentList = []; let paymentModeList = [];
      if (states.modOutpatient && states.modInpatient && states.modLaboratory && states.modRadiology && states.modOt) {
        moduleType?.push('all')
      } else {
        if (states.modOutpatient) {
          moduleType?.push('op')
        }
        if (states.modInpatient) {
          moduleType?.push('ip')
        }
        if (states.modLaboratory) {
          moduleType?.push('lab')
        }
        // if (states.modRadiology) {
        //   moduleType?.push('radiology')
        // }
        // if (states.modOt) {
        //   moduleType?.push('ot')
        // }
      }

      if (states.depOutpatient && states.depInpatient && states.depLaboratory && states.depRadiology && states.depOt) {
        departmentList?.push('all')
      } else {
        if (states.depOutpatient) {
          departmentList?.push('op')
        }
        if (states.depInpatient) {
          departmentList?.push('ip')
        }
        if (states.depLaboratory) {
          departmentList?.push('lab')
        }
        // if (states.depRadiology) {
        //   departmentList?.push('radiology')
        // }
        // if (states.depOt) {
        //   departmentList?.push('ot')
        // }
      }

      if (states.cashSelected) {
        paymentModeList?.push(1)
      }
      if (states.cardSelected) {
        paymentModeList?.push(2)
      }
      if (states.upiSelected) {
        paymentModeList?.push(3)
      }
      if (states.bankSelected) {
        paymentModeList?.push(5)
      }
      if (states.chequeSelected) {
        paymentModeList?.push(4)
      }
      let startDate = new Date(states.datePicker)
      let date = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
      RestAPIService.getAccReport(Serviceurls.ACC_BILL_WISE_RPT_GET +
        '?patient_name=' + this.state.patientName +
        "&uhid=" + this.state.accountNumber +
        '&mobile_no=' + this.state.mobileNumber +
        '&payment_mode=' + paymentModeList +
        '&department=' + departmentList +
        '&module_type=' + moduleType +
        '&from_date=' + date +
        '&export_type=pdf'
      ).then((response) => {
        const file = new Blob(
          [response.data],
          { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        this.setState({ printLoad: false })
      }).catch((error) => {
        this.setState({ printLoad: false })
        if (error?.response?.data?.message) {
          this.errorMessage(error.response.data.message)
        } else {
          this.errorMessage(error.message)
        }
      })
    } catch (error) {
      this.setState({ printLoad: false })
      this.errorMessage(error.message)
    }
  }
  gridToolBar = () => {
    var { t } = this.props
    var states = this.state
    let datepic = new Date(states.datePicker)
    let formatDate = DateTime.fromJSDate(datepic).toFormat('dd-MM-yyyy')
    let TextArray = [
      { label: "Date", value: formatDate }
    ]
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>
    )
  }
  onChangeHandler = (e, names) => {
    try {
      let value = e.target.value
      switch (names) {
        case 'patientName':
          if (CommonValidation.alphabetOnly(value) || value === '') {
            this.setState({ patientName: value })
          }
          break;
        case 'accountNumber':
          this.setState({ accountNumber: value })
          break;
        case 'mobileNumber':
          if (CommonValidation.numberOnly(value) && value?.length <= 10 || value === '') {
            this.setState({ mobileNumber: value })
          }
          break;
        default:
          this.setState({ [names]: value })
          break;
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  textBox = (names, values, labels, width) => {
    try {
      return (
        <TextField
          autoComplete='off'
          size='small'
          sx={{ width: width ? width : '15vw', mb: '1vw' }}
          value={values}
          inputProps={{ emed_tid: labels }}
          label={labels}
          name={names}
          onChange={(e) => this.onChangeHandler(e, names)}
          InputProps={{ emed_tid: names }}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearHandler = () => {
    try {
      this.setState({
        page: 0,
        pageSize: 10,
        showBox: false,
        FilterOpen: false,

        // Filter State
        DatePickerOpen: false,
        datePicker: new Date('2024-04-01'),
        patientName: '',
        mobileNumber: '',
        accountNumber: '',
        depInpatient: true,
        depOutpatient: true,
        depLaboratory: true,
        depOt: true,
        depRadiology: true,
        modRadiology: true,
        modInpatient: true,
        modOutpatient: true,
        modLaboratory: true,
        modOt: true,
        modRadiology: true,
        cashSelected: true,
        cardSelected: true,
        upiSelected: true,
        bankSelected: true,
        chequeSelected: true
      }, () => this.getBillwiseReportData())
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  checkBoxHandler = (e, key) => {
    try {
      let value = e.target.checked
      this.setState({ [key]: value })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderFilterScreen = () => {
    const { t } = this.props
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{("Filter")}</Typography>
          <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn' emed_tid='flt_cls'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box component={'div'} p={'0.5vw'} maxHeight={'75dvh'} overflow={'auto'}>
          <Box component={'div'} mb={'1vw'} display={'flex'} justifyContent={'flex-end'} mr={'0.5vw'}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={this.state.DatePickerOpen}
                onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                onClose={() => { this.setState({ DatePickerOpen: false }) }}
                value={this.state.datePicker}
                minDate={this.state.minDate}
                maxDate={this.state.maxDate}
                name={"datePicker"}
                inputFormat='DD-MM-YYYY'
                // maxDate={new Date(new Date().setDate(new Date().getDate() - 1))}
                onChange={(value) => this.setState({ datePicker: value })}
                renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '12vw' }} size='small' {...params} inputProps={{ ...params.inputProps, 'emed_tid': 'datepicker_billwise_testid', }} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
              />
            </LocalizationProvider>
          </Box>
          <Box component={'div'}>
            {this.textBox('patientName', this.state.patientName, 'Patient Name', '23.5vw')}
            {this.textBox('accountNumber', this.state.accountNumber, 'Account Number', '23.5vw')}
            {this.textBox('mobileNumber', this.state.mobileNumber, 'Mobile Number', '23.5vw')}
          </Box>
          <Box component={'div'} className='eMed_Filter_Header' >
            <Typography fontWeight={600}>Module Type</Typography>
          </Box>
          <Box component={'div'}   >
            <FormControlLabel checked={this.state.modOutpatient} control={<Checkbox emed_tid={"modOutpatient_TestID"} size='small' />} onChange={(e) => this.checkBoxHandler(e, 'modOutpatient')} label="Outpatient" />
            <FormControlLabel checked={this.state.modInpatient} control={<Checkbox emed_tid={"modInpatient_TestID"} size='small' />} onChange={(e) => this.checkBoxHandler(e, 'modInpatient')} label="Inpatient" />
            <FormControlLabel checked={this.state.modLaboratory} control={<Checkbox emed_tid={"modLaboratory_TestID"} size='small' />} onChange={(e) => this.checkBoxHandler(e, 'modLaboratory')} label="Laboratory" />
            {/* <FormControlLabel checked={this.state.modRadiology} control={<Checkbox emed_tid ={"modRadiology_TestID"}  size='small' />} onChange={(e) => this.checkBoxHandler(e, 'modRadiology')} label="Radiology" /> */}
            {/* <FormControlLabel checked={this.state.modOt} control={<Checkbox emed_tid ={"modOt_TestID"}  size='small' />} onChange={(e) => this.checkBoxHandler(e, 'modOt')} label="OT" /> */}
          </Box>

          <Box component={'div'} className='eMed_Filter_Header' >
            <Typography fontWeight={600}>Department</Typography>
          </Box>
          <Box component={'div'}  >
            <FormControlLabel checked={this.state.depOutpatient} control={<Checkbox emed_tid={"depOutpatient_TestID"} size='small' />} onChange={(e) => this.checkBoxHandler(e, 'depOutpatient')} label="Outpatient" />
            <FormControlLabel checked={this.state.depInpatient} control={<Checkbox emed_tid={"depInpatient_TestID"} size='small' />} onChange={(e) => this.checkBoxHandler(e, 'depInpatient')} label="Inpatient" />
            <FormControlLabel checked={this.state.depLaboratory} control={<Checkbox emed_tid={"depLaboratory_TestID"} size='small' />} onChange={(e) => this.checkBoxHandler(e, 'depLaboratory')} label="Laboratory" />
            {/* <FormControlLabel checked={this.state.depRadiology} control={<Checkbox emed_tid ={"depRadiology_TestID"} size='small' />} onChange={(e) => this.checkBoxHandler(e, 'depRadiology')} label="Radiology" /> */}
            {/* <FormControlLabel checked={this.state.depOt} control={<Checkbox emed_tid ={"depOt_TestID"}  size='small' />} onChange={(e) => this.checkBoxHandler(e, 'depOt')} label="OT" /> */}
          </Box>

          {/* <Box component={'div'} className='eMed_Filter_Header' >
            <Typography fontWeight={600}>Payment Mode</Typography>
          </Box>
          <Box component={'div'}>
            <FormControlLabel checked={this.state.cashSelected} control={<Checkbox emed_tid={"cashSelected_TestID"} size='small' />} onChange={(e) => this.checkBoxHandler(e, 'cashSelected')} label="Cash" />
            <FormControlLabel checked={this.state.cardSelected} control={<Checkbox emed_tid={"cardSelected_TestID"} size='small' />} onChange={(e) => this.checkBoxHandler(e, 'cardSelected')} label="Card" />
            <FormControlLabel checked={this.state.upiSelected} control={<Checkbox emed_tid={"upiSelected_TestID"} size='small' />} onChange={(e) => this.checkBoxHandler(e, 'upiSelected')} label="UPI" />
            <FormControlLabel checked={this.state.bankSelected} control={<Checkbox emed_tid={"dbankSelected_TestID"} size='small' />} onChange={(e) => this.checkBoxHandler(e, 'bankSelected')} label="Bank TFR" />
            <FormControlLabel checked={this.state.chequeSelected} control={<Checkbox emed_tid={"chequeSelected_TestID"} size='small' />} onChange={(e) => this.checkBoxHandler(e, 'chequeSelected')} label="Cheque" />
          </Box> */}

        </Box>
        <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} mt={'6vh'}>
          <Button emed_tid={'onClearHandler_TestID'} variant='outlined'
            sx={{ textTransform: 'capitalize', mr: '1vw', height: '2vw' }}
            onClick={() => this.onClearHandler()}
          >Clear</Button>
          <Button emed_tid={'Search_TestID'} variant='contained'
            sx={{ textTransform: 'capitalize', mr: '1vw', height: '2vw' }}
            onClick={() => { this.setState({ FilterOpen: false }, () => this.getBillwiseReportData()) }}
          >Search</Button>
        </Box>
      </Box>
    )
  }

  render() {
    this.state.billwiseReportData?.forEach((element, index) => element.sno = index + 1)
    const columns = [
      {
        field: 'patient_name', headerName: "Patient Details", flex: 0.077,
        renderCell: (params) => {
          let patientName = params?.row?.patient_name + "/" + params?.row?.patient_age
          let accNumAndMob = (params?.row?.patient_uhid ? params?.row?.patient_uhid : "-") + "|" + params?.row?.patient_mobile_number
          return (
            <Box component={'div'} display={'flex'} emed_tid='pat_dtls'>
              {/* <Box component={'div'}>
                <Box component={'img'} mr={'0.5vw'} src={ImagePaths.Male.default} height={'2.5vw'} width={'2.5vw'} borderRadius={'50px'} />
              </Box> */}
              <Box component={'div'}>
                {patientName ? patientName?.length > 12 ?
                  <Tooltip placement='top' title={patientName}><Typography fontSize={'0.9vw'} fontWeight={600}>{patientName?.slice(0, 12) + "..."}</Typography></Tooltip> :
                  <Typography fontSize={'0.9vw'} fontWeight={600}>{patientName}</Typography> : '-'}
                {accNumAndMob ? accNumAndMob?.length > 12 ?
                  <Tooltip placement='top' title={accNumAndMob}><Typography fontSize={'0.8vw'} color={Colors.grayShade}>{accNumAndMob?.slice(0, 12) + "..."}</Typography></Tooltip> :
                  <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{accNumAndMob}</Typography> : '-'}
              </Box>
            </Box>
          )
        }
      },
      {
        field: 'invoice_number', headerName: "Bill Details", flex: 0.077, align: 'center', headerAlign: 'center',
        renderCell: (params) => {
          return (
            <Box component={'div'} emed_tid='bill_num'>
              <Typography fontSize={'0.9vw'} fontWeight={600}>{params?.row?.invoice_number ?
                params?.row?.invoice_number?.length > 10 ?
                  <Tooltip placement='top' title={params?.row?.invoice_number}><div style={{ fontSize: '0.9vw', fontWeight: 600 }}>{"..." + params?.row?.invoice_number?.slice(-8)}</div></Tooltip> :
                  params?.row?.invoice_number : '-'}</Typography>
            </Box>
          )
        }
      },
      {
        field: 'department', headerName: "Department", flex: 0.077,
        renderCell: (params) => {
          return (
            <Box emed_tid='dept'>{params?.row?.department ? params?.row?.department : '-'}</Box>
          )
        }
      },
      {
        field: 'module_type', headerName: "Module Type", flex: 0.077,
        renderCell: (params) => {
          return (
            <Box emed_tid='mod_type'>{params?.row?.module_type ? params?.row?.module_type : '-'}</Box>
          )
        }
      },
      {
        field: 'common_total_amount', headerName: `Bill Amount (${CurrencySymbol})`, flex: 0.077, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_bill'>{params?.row?.common_total_amount ? params?.row?.common_total_amount : 0}</Box>
          )
        }
      },
      {
        field: 'advance_amount', headerName: `Advance (${CurrencySymbol})`, flex: 0.077, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_adv'>{params?.row?.advance_amount ? params?.row?.advance_amount : 0}</Box>
          )
        }
      },
      {
        field: 'discount_amount', headerName: `Concession (${CurrencySymbol})`, flex: 0.077, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_adv'>{params?.row?.discount_amount ? params?.row?.discount_amount : 0}</Box>
          )
        }
      },
      {
        field: 'cash_amount', headerName: `Cash (${CurrencySymbol})`, flex: 0.077, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_cash'>{params?.row?.cash_amount ? params?.row?.cash_amount : 0}</Box>
          )
        }
      },
      {
        field: 'card_amount', headerName: `Card (${CurrencySymbol})`, flex: 0.077, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_card'>{params?.row?.card_amount ? params?.row?.card_amount : 0}</Box>
          )
        }
      },
      {
        field: 'upi_amount', headerName: `UPI (${CurrencySymbol})`, flex: 0.077, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_upi'>{params?.row?.upi_amount ? params?.row?.upi_amount : 0}</Box>
          )
        }
      },
      {
        field: 'bank_transfer_amount', headerName: `Bank Transfer (${CurrencySymbol})`, flex: 0.077, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_bank'>{params?.row?.bank_transfer_amount ? params?.row?.bank_transfer_amount : 0}</Box>
          )
        }
      },
      {
        field: 'cheque_amount', headerName: `Cheque (${CurrencySymbol})`, flex: 0.077, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_bank'>{params?.row?.cheque_amount ? params?.row?.cheque_amount : 0}</Box>
          )
        }
      },
      {
        field: 'credit_amount', headerName: `Credit (${CurrencySymbol})`, flex: 0.077, type: 'number',
        renderCell: (params) => {
          return (
            <Box emed_tid='amt_credit'>{params?.row?.credit_amount ? params?.row?.credit_amount : 0}</Box>
          )
        }
      },
    ]
    return (
      <Box component={'div'}>
        <Box component={'div'} className='eMed_Main_Container'>
          <Box component={'div'} className='eMed_Header_Container'>
            <Box component={'div'} className='eMed_Header_Rgt_Content'>
              {AmountsCard('Total Bill Amount', this.state.totalbillAmount, false, 'white', false, 'white', 'billAmt')}
              <Button emed_tid={'showBox_TestID'} className='Common_Btn_Min_Width' sx={{ textTransform: 'capitalize', width: '11vw' }}
                onClick={() => this.setState({ showBox: !this.state.showBox })}
              >
                {AmountsCard('Total Receive Amount', this.state.totalReceiveAmount, false, this.state.showBox ? Colors.Primary : "white", true, this.state.showBox ? "white" : Colors.Primary, 'rec_recev_amt')}
              </Button>
              {AmountsCard('Advance', this.state.totalAdvanceAmt, false, 'white', false, 'white', 'advAmttlt')}
              {AmountsCard('Concession', this.state.concession, false, 'white', false, 'white', 'concession')}
              {AmountsCard('Credit', this.state.credit, false, 'white', false, 'white', 'credit')}
              <Box component={'div'} sx={{ maxWidth: '25vw', overflow: 'auto', maxHeight: '15dvh' }}>
                {this.state.showBox ?
                  <Box component={'div'} sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', pt: '0.9vw' }}>
                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                      {...(this.state.showBox ? { timeout: 500 } : {})}>{AmountsCard(("Cash"), this.state.cash)}</Grow>
                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                      {...(this.state.showBox ? { timeout: 1000 } : {})}>{AmountsCard(("Card"), this.state.card)}</Grow>
                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                      {...(this.state.showBox ? { timeout: 1500 } : {})}>{AmountsCard(("UPI"), this.state.upi)}</Grow>
                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                      {...(this.state.showBox ? { timeout: 2000 } : {})}>{AmountsCard(("Bank TFR"), this.state.bank)}</Grow>
                    <Grow in={this.state.showBox} style={{ transformOrigin: '0 0 0' }}
                      {...(this.state.showBox ? { timeout: 2500 } : {})}>{AmountsCard(("Cheque"), this.state.cheque)}</Grow>
                  </Box> : null}
              </Box>
            </Box>
            <Box component={'div'} className='eMed_Header_Lft_Content'>
              <Button className='Common_Btn_Min_Width eMed_Header_Btn' emed_tid='flt_btn'
                onClick={() => this.setState({ FilterOpen: true })}
              >
                <Box component={'img'} src={ImagePaths.Filter.default} height={'2vw'} width={'2vw'} />
              </Button>
              <Button className='Common_Btn_Min_Width eMed_Header_Btn' emed_tid='prt_btn'
                disabled={this.state.printLoad}
                onClick={() => { this.setState({ printLoad: true }, () => this.getBillwiseReportDataPrint()) }}
              >
                <Box component={'img'} src={ImagePaths.LabPrintIcon.default} height={'2vw'} width={'2vw'} />
              </Button>
            </Box>
          </Box>
          <Box component={'div'} className='eMed_Table_Content'>
            <DataGrid
              sx={{ backgroundColor: 'white' }}
              columns={columns}
              rows={this.state.billwiseReportData}
              getRowId={(row) => row['sno']}
              page={this.state.page}
              pageSize={this.state.pageSize}
              localeText={{
                toolbarColumns: "",
                toolbarDensity: "",
                toolbarExport: "",
                toolbarFilters: "",
                toolbarExportPrint: ""
              }}
              components={{
                Toolbar: this.gridToolBar,
                NoRowsOverlay: () => (
                  <Box className='eMed_conf_nodata'>
                    {'No Records To Be Shown'}
                  </Box>
                )
              }}
              onPageChange={(newPage) => { this.setState({ page: newPage }) }}
              onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
              rowsPerPageOptions={[10, 20, 30]}
              pagination
              headerHeight={40}
              disableSelectionOnClick
              loading={this.state.loading}
            />
          </Box>
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
      </Box>
    )
  }
}
